import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import * as XLSX from "xlsx";
import { MdDelete } from "react-icons/md";
import { BsCloudUploadFill, BsFiletypeCsv, BsFiletypeXls, BsFiletypeXlsx } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import baseApiService from "../../services/baseApiService";

const MultipleFileUpload = () => {
    const [files, setFiles] = useState([]);
    const [fileErrors, setFileErrors] = useState({});
    const [messageVisible, setMessageVisible] = useState(false);
    const [messageText, setMessageText] = useState([]);
    const [maxFiles] = useState(20); // Maximum files allowed to upload
    const [uploadedFilesCount, setUploadedFilesCount] = useState(0); // Track uploaded files count
   

    const handleDrop = async (acceptedFiles) => {
        try {
            for (const file of acceptedFiles) {
                if (files.some((existingFile) => existingFile.name === file.name)) {
                    toast.warn(`${file.name} has already been added.`, { autoClose: 8000 });
                    continue;
                }

                if (files.length >= maxFiles) {
                    toast.warn(`Maximum ${maxFiles} files allowed.`, { autoClose: 8000 });
                    return;
                }

                let isValidFile = true;
                let errorMessage = "";

                if (
                    file.name.endsWith(".csv") ||
                    file.name.endsWith(".xls") ||
                    file.name.endsWith(".xlsx")
                ) {
                    const reader = new FileReader();
                    reader.readAsArrayBuffer(file);

                    await new Promise((resolve, reject) => {
                        reader.onload = async (e) => {
                            try {
                                const workbook = new Uint8Array(e.target.result);
                                const wb = XLSX.read(workbook, { type: "array" });
                                const sheetNames = wb.SheetNames;

                                if (sheetNames.length !== 2) {
                                    isValidFile = false;
                                    errorMessage = `${file.name} must have exactly two sheets.`;

                                } else {
                                    const firstSheetName = sheetNames[0];
                                    const firstSheet = wb.Sheets[firstSheetName];
                                    const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
                                    const requiredFields = [
                                        "Title", "Description", "Data Source", "Data Source Link",
                                        "Main category", "First Subcategory", "Year Range",
                                        "Meta Tags", "Suggested Visualization",
                                        "Any data transformation or cleaning performed on the data set",
                                        "Data completeness", "Data timeliness",
                                        "Data classification", "Data transformation or cleaning performed by",
                                        "Data sourced by person", "Reviewed and Approved by"
                                    ].map(field => field.toLowerCase().trim());
                                    let missingFields = [];
                                    for (const field of requiredFields) {
                                        const fieldValue = jsonData.find(row => (row[0]?.toLowerCase().trim() ?? '') === field)?.[1];
                                        if (!fieldValue || (fieldValue.trim && fieldValue.trim() === "")) {
                                            missingFields.push(field);
                                        }
                                    }
                                    if (missingFields.length > 0) {
                                        const errorMessage = `Lacks required fields: ${missingFields.join(', ')}`;
                                        setFileErrors(prevErrors => ({
                                            ...prevErrors,
                                            [file.name]: errorMessage
                                        }));
                                    }
                                }
                                resolve();
                            } catch (error) {
                                reject(error);
                            }
                        };

                        reader.onerror = (error) => {
                            isValidFile = false;
                            errorMessage = `Error reading ${file.name}: ${error}`;
                            reject(error);
                        };
                    });
                } else {
                    isValidFile = false;
                    errorMessage = `${file.name} is an unsupported file type.`;
                }

                if (!isValidFile) {
                    toast.error(errorMessage, { autoClose: 8000 });
                } else {
                    setFiles(prevFiles => [...prevFiles, file]);
                    setUploadedFilesCount(prevCount => prevCount + 1);
                    if (errorMessage) {
                        toast.error(errorMessage, { autoClose: 8000 });
                    }
                }
            }
        } catch (error) {
            console.error("Error handling dropped files:", error);
            toast.error("Error handling dropped files:", error, { autoClose: 8000 });
        }
    };
   
    const handleSave = async () => {
        if (files.length === 0) {
            setMessageText(["Please select a file to upload."]);
            setMessageVisible(true);
            return;
        }

        try {
            const apiUrl = `${process.env.REACT_APP_API_BASE_URL}v1/FileUpload/UploadFileV2`;
            const formData = new FormData();
            files.forEach((file) => {
                formData.append("files", file);
            });

            const response = await baseApiService.post(apiUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    
                }
            });

            console.log('Full Response:', response);

            if (response.status === 200 && response.data && response.data.data) {
                const data = response.data.data;
                const { fileStatus, failedUploads } = data.value || {};

                let successMessages = [];
                let errorMessages = [];

                if (fileStatus && fileStatus.length > 0) {
                    successMessages = fileStatus;
                }

                if (failedUploads && failedUploads.length > 0) {
                    errorMessages = failedUploads;
                }

                const allMessages = [...successMessages, ...errorMessages];

                if (allMessages.length > 0) {
                    setMessageText(allMessages);
                    setMessageVisible(true);
                }

                setFiles([]);
                setUploadedFilesCount(0);
            } else {
                setMessageText(["Failed to upload files. Please try again later."]);
                setMessageVisible(true);
            }
        } catch (error) {
            console.error('Error uploading files:', error);

            setMessageText(["An error occurred while uploading files. Please try again later."]);
            setMessageVisible(true);
            if (error.response) {
                console.error('Error response:', error.response);
            }
        }
    };

    const MessageDisplay = () => {
        const [updateCount, setUpdateCount] = useState(0);
        const [successCount, setSuccessCount] = useState(0);
        const [errorCount, setErrorCount] = useState(0);

        const countMessages = () => {
            let updateIndex = 0;
            let successIndex = 0;
            let errorIndex = 0;

            messageText.forEach((item) => {
                if (item.includes("Updated")) {
                    updateIndex++;
                } else if (item.includes("Successfully")) {
                    successIndex++;
                } else if (item.includes("errors")) {
                    errorIndex++;
                }
            });

            setUpdateCount(updateIndex);
            setSuccessCount(successIndex);
            setErrorCount(errorIndex);
        };

        useEffect(() => {
            countMessages();
        }, []);

        return (
            <div className="fixed top-0 left-0 right-0 bottom-0 flex flex-col sm:flex-row backdrop-blur-lg min-w-min items-center justify-center z-50">
                <div className="bg-white p-4 rounded shadow-lg border w-full sm:w-auto mx-auto">
                    <h2 className="text-lg sm:text-xl font-semibold p-2 ">Messages</h2>
                    <div className="flex flex-col gap-2 w-full sm:w-auto">
                        <ul className=" text-xs sm:text-sm text-[#36678e] flex flex-col gap-2 max-h-48 overflow-y-auto scrollbar-blue">
                            {messageText.map((item, index) => (
                                <li key={index} className={`p-2 ${item.includes("errors") || item.includes("Please") ? "bg-red-50 text-red-600" : "bg-green-50 text-green-600"} rounded-md`}>
                                    {item}
                                </li>
                            ))}
                        </ul>
                        <div className="flex flex-col sm:flex-row justify-between p-2 text-sm gap-2 ">
                            <p><strong>Updates:</strong> {updateCount}</p>
                            <p><strong>Successes:</strong> {successCount}</p>
                            <p><strong>Errors:</strong> {errorCount}</p>
                        </div>
                        <div className="flex justify-center items-center ">
                            <button onClick={() => setMessageVisible(false)} className="px-4 py-2 bg-[#36678e] text-white rounded">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const getFileIcon = (fileName) => {
        if (fileName.endsWith(".csv")) {
            return <BsFiletypeCsv />;
        } else if (fileName.endsWith(".xls")) {
            return <BsFiletypeXls />;
        } else if (fileName.endsWith(".xlsx")) {
            return <BsFiletypeXlsx />;
        } else {
            return null;
        }
    };

    const removeFile = (index) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
        setUploadedFilesCount((prevCount) => prevCount - 1);
    };

    const handleCancel = () => {
        setFiles([]);
        setFileErrors({});
        setUploadedFilesCount(0);
        setMessageVisible(false);
        setMessageText([]);
    };

    return (
        <div className="flex flex-col bg-blue-50 border max-h-[90vh] font-Inter rounded mx-auto overflow-hidden sm:w-full lg:w-[900px]">
            {messageVisible && <MessageDisplay />}
            <>
                <Dropzone onDrop={handleDrop} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div
                                {...getRootProps()}
                                className="font-Inter medium text-sm bg-[#f8f8f8] border mx-8 mt-12 shadow-md border-gray-300 rounded-t-md p-4 text-center flex flex-col items-center justify-center"
                            >
                                <input {...getInputProps()} />
                                <span className="text-3xl md:text-5xl p-2 text-[#36678E]">
                                    <BsCloudUploadFill />
                                </span>
                                <span className="text-[#36678E]">Click to Upload or Drag and drop</span>
                                <span className="text-gray-400">csv, xls, xlsx </span>
                                {uploadedFilesCount < maxFiles ? null : (
                                    <p className="text-red-600 font-medium">Maximum files reached. Only 20 allowed at a time.</p>
                                )}
                            </div>
                        </section>
                    )}
                </Dropzone>

                <ToastContainer className="bg-blue-50 text-black max-h-[80vh] overflow-y-auto scrollbar-blue" />

                <div className="mx-8 mt-2 max-h-80 overflow-y-auto scrollbar-blue">
                    {files.map((file, index) => (
                        <li
                            key={index}
                            className={`flex items-center justify-between py-2 border mt-2 ${
                                fileErrors[file.name] ? "bg-red-200" : "bg-[#f8f8f8]"
                            } shadow-md rounded border-gray-300`}
                        >
                            <div className="px-2 text-sm text-[#36678e] flex flex-row gap-2">
                                <span>{index + 1}.</span>
                                <span className="flex items-center text-sm justify-center border rounded-full shadow-[#36678e] shadow-sm w-5 h-5 p-1">
                                    {getFileIcon(file.name)}
                                </span>
                                <span> {file.name}</span>
                                <span>-</span>
                                <span>{file.size} bytes</span>
                            </div>
                            <button className="text-gray-400 text-2xl " onClick={() => removeFile(index)}>
                                <MdDelete className="mr-4" />
                            </button>
                        </li>
                    ))}
                </div>

                <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between gap-4 py-4 mx-8">
                    <button
                        onClick={handleCancel}
                        className="flex items-center justify-center sm:w-full w-full shadow-md h-[34px] rounded-[6px] border-[1px] border-[#D1D5DB] bg-white sm:mb-0"
                    >
                        <span className="font-Inter  text-[14px] text-[#18191f]">Clear</span>
                    </button>
                    <button
                        onClick={handleSave}
                        className="flex items-center justify-center sm:w-full w-full shadow-md h-[34px] rounded-[6px] border-[1px] border-[#D1D5DB] bg-[#36678E] text-white"
                    >
                        <span className="font-Inter  text-[14px]">Upload</span>
                    </button>
                </div>
            </>
        </div>
    );
};

export default MultipleFileUpload;
